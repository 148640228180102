import React from 'react';
import Form from 'react-bootstrap/Form';
import defaults from '../defaults';

export const getAudioGraphsConfigs = () => {
  let continuous = defaults.voiceover.audioGraphs.continuous;
  let soundType = defaults.voiceover.audioGraphs.soundType;
  let speed = defaults.voiceover.audioGraphs.speed;

  if (document.querySelector('input[name="audioGraphs.continuous"]:checked'))
    continuous = document.querySelector(
      'input[name="audioGraphs.continuous"]:checked'
    ).value;

  if (document.querySelector('input[name="audioGraphs.soundType"]:checked'))
    soundType = document.querySelector(
      'input[name="audioGraphs.soundType"]:checked'
    ).value;

  if (document.querySelector('input[name="audioGraphs.speed"]:checked'))
    speed = document.querySelector(
      'input[name="audioGraphs.speed"]:checked'
    ).value;

  return { continuous, soundType, speed };
};

const AudioGraphs = () => (
  <>
    <tr>
      <td>Continuous</td>
      <td>
        <Form.Check
          type="radio"
          name="audioGraphs.continuous"
          value={true}
          label="Yes"
          inline
        />
        <Form.Check
          type="radio"
          name="audioGraphs.continuous"
          value={false}
          label="No"
          inline
        />
      </td>
    </tr>
    <tr>
      <td>Sound Type</td>
      <td>
        <Form.Check
          type="radio"
          name="audioGraphs.soundType"
          value="musical"
          label="Musical (Instruments)"
          inline
        />
        <Form.Check
          type="radio"
          name="audioGraphs.soundType"
          value="oscillator"
          label="Oscillator (Traditional Computer Sounds)"
          inline
        />
      </td>
    </tr>
    <tr>
      <td>Speed</td>
      <td>
        <Form.Check
          type="radio"
          name="audioGraphs.speed"
          value={1}
          label="Slow"
          inline
        />
        <Form.Check
          type="radio"
          name="audioGraphs.speed"
          value={2}
          label="Medium"
          inline
        />
        <Form.Check
          type="radio"
          name="audioGraphs.speed"
          value={3}
          label="Fast"
          inline
        />
      </td>
    </tr>
  </>
);

export default AudioGraphs;
