import React from 'react';
import Form from 'react-bootstrap/Form';
import defaults from '../defaults';

export const getSummaryConfigs = () => {
  let title = defaults.voiceover.summary.title;
  let chartType = defaults.voiceover.summary.chartType;
  let average = defaults.voiceover.summary.average;
  let minimum = defaults.voiceover.summary.minimum;
  let maximum = defaults.voiceover.summary.maximum;
  let stdev = defaults.voiceover.summary.stdev;
  let variance = defaults.voiceover.summary.variance;

  if (document.querySelector('input[name="summary.title"]:checked'))
    title = document.querySelector('input[name="summary.title"]:checked').value;

  if (document.querySelector('input[name="summary.chartType"]:checked'))
    chartType = document.querySelector(
      'input[name="summary.chartType"]:checked'
    ).value;

  if (document.querySelector('input[name="summary.average"]:checked'))
    average = document.querySelector(
      'input[name="summary.average"]:checked'
    ).value;

  if (document.querySelector('input[name="summary.minimum"]:checked'))
    minimum = document.querySelector(
      'input[name="summary.minimum"]:checked'
    ).value;

  if (document.querySelector('input[name="summary.maximum"]:checked'))
    maximum = document.querySelector(
      'input[name="summary.maximum"]:checked'
    ).value;

  if (document.querySelector('input[name="summary.stdev"]:checked'))
    stdev = document.querySelector('input[name="summary.stdev"]:checked').value;

  if (document.querySelector('input[name="summary.variance"]:checked'))
    variance = document.querySelector(
      'input[name="summary.variance"]:checked'
    ).value;

  return {
    title,
    chartType,
    average,
    minimum,
    maximum,
    stdev,
    variance,
  };
};

const Summary = () => (
  <>
    <tr>
      <td>Title</td>
      <td>
        <Form.Check
          type="radio"
          name="summary.title"
          value={true}
          label="Yes"
          inline
        />
        <Form.Check
          type="radio"
          name="summary.title"
          value={false}
          label="No"
          inline
        />
      </td>
    </tr>
    <tr>
      <td>Chart Type</td>
      <td>
        <Form.Check
          type="radio"
          name="summary.chartType"
          value={true}
          label="Yes"
          inline
        />
        <Form.Check
          type="radio"
          name="summary.chartType"
          value={false}
          label="No"
          inline
        />
      </td>
    </tr>
    <tr>
      <td>Average</td>
      <td>
        <Form.Check
          type="radio"
          name="summary.average"
          value={true}
          label="Yes"
          inline
        />
        <Form.Check
          type="radio"
          name="summary.average"
          value={false}
          label="No"
          inline
        />
      </td>
    </tr>
    <tr>
      <td>Minimum</td>
      <td>
        <Form.Check
          type="radio"
          name="summary.minimum"
          value={true}
          label="Yes"
          inline
        />
        <Form.Check
          type="radio"
          name="summary.minimum"
          value={false}
          label="No"
          inline
        />
      </td>
    </tr>
    <tr>
      <td>Maximum</td>
      <td>
        <Form.Check
          type="radio"
          name="summary.maximum"
          value={true}
          label="Yes"
          inline
        />
        <Form.Check
          type="radio"
          name="summary.maximum"
          value={false}
          label="No"
          inline
        />
      </td>
    </tr>
    <tr>
      <td>Standard Deviation</td>
      <td>
        <Form.Check
          type="radio"
          name="summary.stdev"
          value={true}
          label="Yes"
          inline
        />
        <Form.Check
          type="radio"
          name="summary.stdev"
          value={false}
          label="No"
          inline
        />
      </td>
    </tr>
    <tr>
      <td>Variance</td>
      <td>
        <Form.Check
          type="radio"
          name="summary.variance"
          value={true}
          label="Yes"
          inline
        />
        <Form.Check
          type="radio"
          name="summary.variance"
          value={false}
          label="No"
          inline
        />
      </td>
    </tr>
  </>
);

export default Summary;
