import React, { Component } from 'react';
import PropTypes, { shape } from 'prop-types';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import flowRight from 'lodash/flowRight';
import get from 'lodash/get';
import AudioGraphs, { getAudioGraphsConfigs } from './AudioGraphs';
import Sorting, { getSortingConfigs } from './Sorting';
import Summary, { getSummaryConfigs } from './Summary';
import Uncertainty, { getUncertaintyConfigs } from './Uncertainty';
import Verbosity, { getVerbosityConfigs } from './Verbosity';
import { withLoading, withRouter } from '../components';
import ImportPreferences from '../ImportPreferences';

import './index.css';

const clientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

class Preferences extends Component {
  static propTypes = {
    data: shape({
      preferences: shape({
        preferences: PropTypes.string.isRequired,
      }).isRequired,
    }),
    loggedIn: PropTypes.bool.isRequired,
    modifyPreferences: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    updateClient: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      alertType: null,
    };
  }

  componentDidMount() {
    this.setOptions();
  }

  componentDidUpdate() {
    this.setOptions();
  }

  setOptions = () => {
    if (!this.props.data?.preferences?.preferences) return;

    const preferences = JSON.parse(this.props.data.preferences.preferences);
    const types = [
      'audioGraphs.continuous',
      'audioGraphs.soundType',
      'audioGraphs.speed',
      'sorting.dependent',
      'sorting.independent',
      'summary.title',
      'summary.chartType',
      'summary.average',
      'summary.minimum',
      'summary.maximum',
      'summary.stdev',
      'summary.variance',
      'uncertainty',
      'verbosity',
    ];

    types.forEach((type) => {
      for (let element of document.getElementsByName(type)) {
        const preference = isNaN(element.value)
          ? element.value
          : parseInt(element.value);

        if (
          preference != null &&
          get(preferences, type).toString() === preference.toString()
        ) {
          element.checked = true;
        }
      }
    });
  };

  sendMessage = (optionsFromFile = null) => {
    let { data, modifyPreferences } = this.props;

    const audioGraphs = getAudioGraphsConfigs();
    const sorting = getSortingConfigs();
    const summary = getSummaryConfigs();
    const uncertainty = getUncertaintyConfigs();
    const verbosity = getVerbosityConfigs(optionsFromFile);

    const preferences = {
      ...JSON.parse(data.preferences.preferences),
      audioGraphs,
      sorting,
      summary,
      uncertainty,
      verbosity,
    };

    const options = {
      variables: {
        preferences: JSON.stringify(preferences),
      },
    };

    modifyPreferences(options).then((d) => {
      const preferences = d.data.modifyPreferences.preferences;

      window.postMessage({
        source: 'voxlens-configuration-portal',
        payload: {
          type: 'setPreferences',
          params: preferences,
        },
      });

      this.setState({
        alert: 'Settings successfully saved',
        alertType: 'success',
      });

      window.scrollTo({ top: 100 });
    });
  };

  onLoginSuccess = (response) => this.props.updateClient(response.credential);

  onLoginFail = (response) => {
    console.log('Login failed: ', response);
    this.setState({
      alert: 'Failed to log in. Please check console logs for details.',
    });
  };

  render() {
    let { data, router } = this.props;

    data = data ? data.preferences : null;

    const isComponentImport = router?.location.pathname.includes('import');

    return (
      <div>
        {this.state.alert && (
          <Alert variant={this.state.alertType || 'success'}>
            {this.state.alert}
          </Alert>
        )}
        {data && !isComponentImport && (
          <div id="preferences">
            <Form>
              <h2>General Preferences</h2>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Setting</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <Verbosity />
                  <Sorting />
                  <Uncertainty />
                </tbody>
              </Table>
              <h2>Summary Preferences</h2>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Setting</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <Summary />
                </tbody>
              </Table>
              <h2>Audio Graphs Preferences</h2>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Setting</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <AudioGraphs />
                </tbody>
              </Table>
            </Form>
            <Button id="save-button" onClick={this.sendMessage}>
              Save Preferences
            </Button>
          </div>
        )}
        {data && isComponentImport && (
          <div>
            <ImportPreferences sendMessage={this.sendMessage} />
          </div>
        )}
        {!data && (
          <div id="login-area">
            <GoogleOAuthProvider clientId={clientId}>
              <GoogleLogin
                auto_select
                useOneTap
                onSuccess={this.onLoginSuccess}
                onError={this.onLoginFail}
              />
            </GoogleOAuthProvider>
          </div>
        )}
      </div>
    );
  }
}

const getPreferences = gql`
  query getPreferences {
    preferences {
      id
      preferences
    }
  }
`;

const modifyPreferences = gql`
  mutation modifyPreferences($preferences: String!) {
    modifyPreferences(preferences: $preferences) {
      id
      preferences
    }
  }
`;

export default flowRight(
  graphql(getPreferences, {
    skip: ({ loggedIn }) => !loggedIn,
  }),
  graphql(modifyPreferences, { name: 'modifyPreferences' }),
  withRouter,
  withLoading()
)(Preferences);
