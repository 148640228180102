import * as d3 from 'd3';
import max from 'lodash/max';
import orderBy from 'lodash/orderBy';
import shuffle from 'lodash/shuffle';
import voxlens from '../../voxlens';
import { getPreferences } from '../../voxlens/utils';

const sortingDep = getPreferences('sorting.dependent')?.toString();
const sortingIndep = getPreferences('sorting.independent')?.toString();

let data = shuffle(require('./data.json'));

const createD3 = () => {
  const voxlensOptions = {
    x: 'name',
    y: 'score',
    title: 'Average Test Scores for Students',
    chartType: 'bar',
  };

  let sortBy = [[], []];

  if (sortingIndep && sortingIndep !== '3') {
    sortBy[0].push(voxlensOptions.x);
    sortBy[1].push(sortingIndep === '1' ? 'asc' : 'desc');
  }

  if (sortingDep && sortingDep !== '3') {
    sortBy[0].push(voxlensOptions.y);
    sortBy[1].push(sortingDep === '1' ? 'asc' : 'desc');
  }

  if (sortBy[0].length > 0) data = orderBy(data, sortBy[0], sortBy[1]);

  const getDimensions = (maxXLabel) => {
    const margin = { top: 20, right: 40, bottom: maxXLabel * 5 + 10, left: 70 };

    return {
      margin,
      height: 500 - margin.top - 20,
      width: container.offsetWidth - margin.left - margin.right,
    };
  };

  const container = document.getElementById('chart');

  let maxXLabel = max(data.map((d) => d['name'].toString().length));
  let { height, margin, width } = getDimensions(maxXLabel);
  let transform = margin.left + ',' + margin.top;

  const svg = d3
    .select('#chart')
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + 40 + margin.top + margin.bottom)
    .append('g')
    .attr('transform', 'translate(' + transform + ')');

  const x = d3.scaleBand().range([0, width]).padding(0.1);
  const y = d3.scaleLinear().range([height, 0]);

  x.domain(data.map((d) => d['name']));
  y.domain([0, d3.max(data, (d) => parseFloat(d['score']))]);

  svg
    .selectAll('.bar')
    .data(data)
    .enter()
    .append('rect')
    .attr('class', 'bar')
    .style('fill', '#4682b4')
    .style('margin', '10px')
    .attr('x', (d) => x(d['name']))
    .attr('width', x.bandwidth())
    .attr('y', (d) => y(d['score']))
    .attr('height', (d) => height - y(d['score']))
    .call((d) => voxlens('d3', d, data, voxlensOptions));

  svg
    .append('text')
    .attr(
      'transform',
      'translate(' + width / 2 + ' ,' + (height + margin.bottom + 20) + ')'
    )
    .style('text-anchor', 'middle')
    .text('Name');

  svg
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('y', 0 - margin.left)
    .attr('x', 0 - height / 2)
    .attr('dy', '1em')
    .style('text-anchor', 'middle')
    .text('Score');

  svg
    .append('g')
    .attr('transform', 'translate(0, ' + height + ')')
    .attr('class', 'bar-axis')
    .call(d3.axisBottom(x).ticks(data.length))
    .selectAll('text')
    .attr('y', 0)
    .attr('x', 9)
    .attr('dy', '.35em')
    .attr('transform', 'rotate(90)')
    .style('text-anchor', 'start')
    .style('opacity', 1);

  svg.append('g').attr('class', 'bar-axis').call(d3.axisLeft(y));
};

export default createD3;
