import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';

import './Preferences/index.css';
import defaults from './defaults';

const ImportPreferences = ({ sendMessage }) => {
  const [alert, setAlert] = useState(null);

  let fileReader;

  const handleFileRead = () => {
    let verbosity = defaults.voiceover.verbosity;
    const content = fileReader.result;

    if (content.search(/SCRVerbosityMedium/i) !== -1) verbosity = 2;
    else if (content.search(/SCRVerbosityLow/i) !== -1) verbosity = 1;

    sendMessage({ verbosity });

    const file = document.querySelector('#file');
    setAlert(
      'File "' +
        file.value.split('\\').pop() +
        '" uploaded and preferences updated successfully.'
    );
    file.value = '';
  };

  const handleFileChosen = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  return (
    <div>
      {alert && <Alert variant="success">{alert}</Alert>}
      <p>
        Upload a configuration file to automatically update preferences.
        Currently, only VoiceOver is supported.
      </p>
      <input
        type="file"
        id="file"
        accept=".voprefs"
        onChange={(e) => handleFileChosen(e.target.files[0])}
      />
    </div>
  );
};

ImportPreferences.propTypes = {
  sendMessage: PropTypes.func.isRequired,
};

export default ImportPreferences;
