import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  createHttpLink,
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Layout, Navigation } from './components';
import Routes from './Routes';

import './App.css';

const GRAPHQL_URI =
  process.env.REACT_APP_SERVER + process.env.REACT_APP_GRAPHQL_URL;
const GRAPHQL_CACHE = new InMemoryCache();

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: this.updateClient(null, true),
      loggedIn: false,
    };
  }

  updateClient = (token) => {
    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }));

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: GRAPHQL_CACHE,
    });

    this.setState({ client, loggedIn: token != null });

    return client;
  };

  render() {
    const { client, loggedIn } = this.state;

    return (
      <Router>
        <ApolloProvider client={client}>
          <Navigation updateClient={this.updateClient} />
          <Layout>
            <Routes loggedIn={loggedIn} updateClient={this.updateClient} />
          </Layout>
        </ApolloProvider>
      </Router>
    );
  }
}

export default App;
