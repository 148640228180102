export const getSynth = (Tone, soundType, options) => {
  let synth = null;

  const middleware = () => {
    if (synth.frequency) synth.frequency.value = options.frequency;
    if (synth.volume) synth.volume.value = options.volume;
    synth.type = options.oscillatorType;
    synth.sync();
  };

  /* istanbul ignore else */
  if (soundType === 'OmniOscillator') {
    synth = new Tone.OmniOscillator(
      options.note,
      options.oscillatorType
    ).toDestination();

    middleware();

    synth.start(options.start).stop(options.stop);
  } else if (soundType === 'MonoSynth') {
    synth = new Tone.MonoSynth({
      oscillator: {
        type: options.oscillatorType,
      },
      envelope: {
        attack: 0.1,
      },
    }).toDestination();

    middleware();

    synth.triggerAttackRelease(options.frequency, '8n', options.start);
  } else if (soundType === 'Envelope') {
    synth = new Tone.Envelope({
      attack: 0.1,
      decay: 0.2,
      sustain: 0.5,
      release: 0.8,
    }).toDestination();

    // middleware();

    synth.triggerAttackRelease(options.frequency, '8n', options.start);
  }

  synth.onstop = () => {
    synth.dispose();
    synth.unsync();
  };

  return synth;
};
