import React from 'react';
import Form from 'react-bootstrap/Form';
import defaults from '../defaults';

export const getUncertaintyConfigs = () => {
  let uncertainty = defaults.voiceover.uncertainty;

  if (document.querySelector('input[name="uncertainty"]:checked')) {
    uncertainty = document.querySelector(
      'input[name="uncertainty"]:checked'
    ).value;
  }

  return uncertainty;
};

const Uncertainty = () => (
  <tr>
    <td>Uncertainty information</td>
    <td>
      <Form.Check
        type="radio"
        name="uncertainty"
        value={true}
        label="Yes"
        inline
      />
      <Form.Check
        type="radio"
        name="uncertainty"
        value={false}
        label="No"
        inline
      />
    </td>
  </tr>
);

export default Uncertainty;
