import React from 'react';
import Form from 'react-bootstrap/Form';
import defaults from '../defaults';

export const getSortingConfigs = () => {
  let dependent = defaults.voiceover.sorting.dependent;
  let independent = defaults.voiceover.sorting.independent;

  if (document.querySelector('input[name="sorting.dependent"]:checked'))
    dependent = document.querySelector(
      'input[name="sorting.dependent"]:checked'
    ).value;

  if (document.querySelector('input[name="sorting.independent"]:checked'))
    independent = document.querySelector(
      'input[name="sorting.independent"]:checked'
    ).value;

  return { dependent, independent };
};

const Sorting = () => (
  <>
    <tr>
      <td>Sorting: Dependent Variable(s)</td>
      <td>
        <Form.Check
          type="radio"
          name="sorting.dependent"
          value={1}
          label="Ascending"
          inline
        />
        <Form.Check
          type="radio"
          name="sorting.dependent"
          value={2}
          label="Descending"
          inline
        />
        <Form.Check
          type="radio"
          name="sorting.dependent"
          value={3}
          label="As determined by developer"
          inline
        />
      </td>
    </tr>
    <tr>
      <td>Sorting: Independent Variable(s)</td>
      <td>
        <Form.Check
          type="radio"
          name="sorting.independent"
          value={1}
          label="Ascending"
          inline
        />
        <Form.Check
          type="radio"
          name="sorting.independent"
          value={2}
          label="Descending"
          inline
        />
        <Form.Check
          type="radio"
          name="sorting.independent"
          value={3}
          label="As determined by developer"
          inline
        />
      </td>
    </tr>
  </>
);

export default Sorting;
