import React from 'react';
import Form from 'react-bootstrap/Form';
import defaults from '../defaults';

export const getVerbosityConfigs = (optionsFromFile) => {
  let verbosity = defaults.voiceover.verbosity;

  if (document.querySelector('input[name="verbosity"]:checked')) {
    verbosity = parseInt(
      document.querySelector('input[name="verbosity"]:checked').value
    );
  }

  if (optionsFromFile && optionsFromFile.verbosity)
    verbosity = optionsFromFile.verbosity;

  return verbosity;
};

const Verbosity = () => (
  <tr>
    <td>Verbosity Level</td>
    <td>
      <Form.Check type="radio" name="verbosity" value={1} label="Low" inline />
      <Form.Check
        type="radio"
        name="verbosity"
        value={2}
        label="Medium"
        inline
      />
      <Form.Check type="radio" name="verbosity" value={3} label="High" inline />
    </td>
  </tr>
);

export default Verbosity;
