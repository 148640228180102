/**
 * @namespace commands.summary
 */

import stats from 'stats-lite';
import max from 'lodash/max';
import min from 'lodash/min';
import { getIndependentValues } from './helpers';
import { addThousandsSeparators, getPreferences } from '../utils';

/**
 * Generates the response for the "summary" command.
 * @memberOf commands.summary
 * @param {Object} data - The data from the viz.
 * @param {string[]} data.x - Values of the independent variable.
 * @param {string[]} data.y - Values of the dependent variable.
 * @param {Object} options - The options supplied to voxlens when creating the viz.
 * @param {string} options.xLabel - Label for the x-axis.
 * @param {number} options.yLabel - Label for the y-axis.
 * @returns {object} - Response for the "summary" command.
 */
export default (data, options) => {
  const preferences = getPreferences('summary');

  let sentence = '';

  if (!preferences || preferences.title !== 'false')
    sentence += `Graph with title: ${options.title}. `;

  if (!preferences || preferences.chartType !== 'false')
    sentence += `Graph type is ${options.chartType}. `;

  sentence += `The X-axis is ${options.xLabel}. The Y-axis is ${options.yLabel}. `;

  if (!preferences || preferences.maximum !== 'false') {
    const maxValues = getIndependentValues(data, max);
    const maximum = addThousandsSeparators(maxValues[0]);

    sentence += `The maximum is ${maximum} ${options.yLabel.toLowerCase()} belonging to ${
      maxValues[1]
    }. `;
  }

  if (!preferences || preferences.minimum !== 'false') {
    const minValues = getIndependentValues(data, min);
    const minimum = addThousandsSeparators(minValues[0]);

    sentence += `The minimum is ${minimum} ${options.yLabel.toLowerCase()} belonging to ${
      minValues[1]
    }. `;
  }

  if (!preferences || preferences.average !== 'false') {
    const average = addThousandsSeparators(stats.mean(data.y));

    sentence += `The average is ${average} ${options.yLabel.toLowerCase()}. `;
  }

  if (!preferences || preferences.stdev !== 'false') {
    const average = addThousandsSeparators(stats.stdev(data.y));

    sentence += `The standard deviation is ${average} ${options.yLabel.toLowerCase()}. `;
  }

  if (!preferences || preferences.variance !== 'false') {
    const average = addThousandsSeparators(stats.variance(data.y));

    sentence += `The variance is ${average} ${options.yLabel.toLowerCase()}. `;
  }

  return { sentence: sentence.trim() };
};
