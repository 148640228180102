import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';

const Header = ({ updateClient }) => (
  <Navbar bg="light" variant="light">
    <Container>
      <Navbar.Brand>VoxLens Configuration Portal</Navbar.Brand>
      <Nav className="me-auto">
        <NavLink exact="true" to="/preferences">
          <span>View Preferences</span>
        </NavLink>
        <NavLink exact="true" to="/preferences/import">
          <span>Import Preferences</span>
        </NavLink>
        <Button
          id="logout-button"
          size="sm"
          onClick={() => {
            googleLogout();
            updateClient();
          }}
        >
          Logout
        </Button>
      </Nav>
    </Container>
  </Navbar>
);

Header.propTypes = {
  updateClient: PropTypes.func.isRequired,
};

export default Header;
