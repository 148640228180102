import React from 'react';
import { Routes as R, Route } from 'react-router';
import Example from './Example';
import Preferences from './Preferences';

const Routes = (appProps) => (
  <R>
    <Route exact="true" path="/example" element={<Example {...appProps} />} />
    <Route exact="true" path="/preferences/*" element={<Preferences {...appProps} />} />
    <Route render={() => <div>404: Page not found</div>} />
  </R>
);

export default Routes;
